
import { defineComponent } from "@vue/composition-api";
import { mapFields } from "vuex-map-fields";

export default defineComponent({
  name: "PlanStep",
  computed: {
    ...mapFields("execution", ["executionPlan.plan.plan"]),
  },
});
