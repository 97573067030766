
import { api, dashboardApi } from "@/api/api";
import { ApiCourseResponsibleFilter, ApiGetCustomerDto, ApiGetDashboardCourseAttendanceDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { getDashboardUserId } from "@/shared/helpers/dashboardHelpers";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { CourseParticipantTabNames } from "../kurs/details/CourseParticipantDetailPage.vue";

export default defineComponent({
  components: { BaseTableFiltered, BaseLayout },
  name: "CourseWithLowAttendancePage",
  setup() {
    const router = useRouter();
    const coursesWithLowAttendance = ref<ApiGetDashboardCourseAttendanceDto[] | null>();
    const search = ref("");
    const users = ref<ApiGetCustomerDto[]>();
    const currentEmployeeId = ref<number>();

    const getParticipantName = (currentId: number) => {
      const participant = users.value?.find((user) => user.id === currentId);
      if (!participant) {
        return "Fant ikke navn på deltaker";
      }
      return `${participant?.customer?.firstName} ${participant?.customer?.lastName}`;
    };

    const displayCourseAttendance = (dashboardCourseAttendance: ApiGetDashboardCourseAttendanceDto) => {
      router.push({
        name: SingleCourseRouteNames.CourseAttendance,
        params: {
          id: dashboardCourseAttendance.courseId.toString(),
        },
      });
    };

    const getUsers = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        users.value = (await api.customer.getCustomerPersonsAsync()).data;
      });
    };

    const getCoursesWithLowAttendance = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        currentEmployeeId.value = await getDashboardUserId();
        coursesWithLowAttendance.value = (
          await dashboardApi.dashboard.getDashboardAsync({
            userId: currentEmployeeId.value,
            courseResponsibleFilter: ApiCourseResponsibleFilter.SUPERVISOR_AND_LECTURER,
          })
        ).data.coursesWithParticipantsWithLowAttendance;
      });
    };

    onMounted(async () => {
      await Promise.all([getCoursesWithLowAttendance(), getUsers()]);
    });

    const navigateToParticipantAttendance = (courseId: number, participantId: number) =>
      router.push({
        name: SingleCourseRouteNames.CourseParticipantDetails,
        params: { id: courseId.toString(), participantId: participantId.toString() },
        query: { tab: CourseParticipantTabNames.Attendence },
      });

    return {
      search,
      headers,
      DashboardRouteNames,
      coursesWithLowAttendance,
      displayCourseAttendance,
      formatLocalizedDate,
      getParticipantName,
      navigateToParticipantAttendance,
    };
  },
});

const headers = [
  { text: "Kurs-ID", value: "courseId" },
  { text: "Handlinger", value: "actions" },
  { text: "Kursnavn", value: "courseName" },
  { text: "Deltagere med lavt fremmøte", value: "courseParticipantsWithLowAttendance" },
];
