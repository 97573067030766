var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "elevation-0",
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-card-title', [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "2 - Plan *",
      "readonly": ""
    },
    model: {
      value: _vm.plan,
      callback: function callback($$v) {
        _vm.plan = $$v;
      },
      expression: "plan"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }