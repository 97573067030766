
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
import { mapFields } from "vuex-map-fields";

export default defineComponent({
  name: "PlanInfoStep",
  data: () => ({
    switch1: true,
  }),
  computed: {
    ...mapFields("execution", [
      "executionPlan.info.reasonForStatusChangeRequired",
      "executionPlan.info.cancellationDeadlineRequired",
      "executionPlan.info.hoursWithoutInstructorRequired",
      "executionPlan.info.hoursSelfStudyRequired",
      "executionPlan.info.enrollmentDeadlineRequired",
      "executionPlan.info.unenrollmentDeadlineRequired",
      "executionPlan.info.minimumParticipantsRequired",
      "executionPlan.info.maximumParticipantsRequired",
      "executionPlan.info.financiersRequired",
      "executionPlan.info.priceRequired",
      "executionPlan.info.additionalFilesRequired",
      "executionPlan.info.groupIdRequired",
    ]),
  },
  setup() {
    const store = useStore<StoreState>();

    return {
      isVocationalSchool: computed(() => isVocationalSchool(store.state.plans.studyplan.mainCourseId)),
    };
  },
});
