var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "elevation-0",
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-card-title', [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "1 - Info *",
      "readonly": ""
    },
    model: {
      value: _vm.switch1,
      callback: function callback($$v) {
        _vm.switch1 = $$v;
      },
      expression: "switch1"
    }
  })], 1), _c('v-container', {
    staticClass: "pa-4"
  }, [_vm.switch1 ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "\n            Begrunnelse for statusendring"
    },
    model: {
      value: _vm.reasonForStatusChangeRequired,
      callback: function callback($$v) {
        _vm.reasonForStatusChangeRequired = $$v;
      },
      expression: "reasonForStatusChangeRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "\n            Avbestillingsfrist lokale"
    },
    model: {
      value: _vm.cancellationDeadlineRequired,
      callback: function callback($$v) {
        _vm.cancellationDeadlineRequired = $$v;
      },
      expression: "cancellationDeadlineRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "\n            Timer u/lærer"
    },
    model: {
      value: _vm.hoursWithoutInstructorRequired,
      callback: function callback($$v) {
        _vm.hoursWithoutInstructorRequired = $$v;
      },
      expression: "hoursWithoutInstructorRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "Timer selvstudie"
    },
    model: {
      value: _vm.hoursSelfStudyRequired,
      callback: function callback($$v) {
        _vm.hoursSelfStudyRequired = $$v;
      },
      expression: "hoursSelfStudyRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": _vm.isVocationalSchool ? 'Søknadsfrist' : 'Påmeldingsfrist'
    },
    model: {
      value: _vm.enrollmentDeadlineRequired,
      callback: function callback($$v) {
        _vm.enrollmentDeadlineRequired = $$v;
      },
      expression: "enrollmentDeadlineRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "\n            Avmeldingsfrist"
    },
    model: {
      value: _vm.unenrollmentDeadlineRequired,
      callback: function callback($$v) {
        _vm.unenrollmentDeadlineRequired = $$v;
      },
      expression: "unenrollmentDeadlineRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "\n            Min. deltakere"
    },
    model: {
      value: _vm.minimumParticipantsRequired,
      callback: function callback($$v) {
        _vm.minimumParticipantsRequired = $$v;
      },
      expression: "minimumParticipantsRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "\n            Maks. deltakere"
    },
    model: {
      value: _vm.maximumParticipantsRequired,
      callback: function callback($$v) {
        _vm.maximumParticipantsRequired = $$v;
      },
      expression: "maximumParticipantsRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "Finansiert av"
    },
    model: {
      value: _vm.financiersRequired,
      callback: function callback($$v) {
        _vm.financiersRequired = $$v;
      },
      expression: "financiersRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "Pris"
    },
    model: {
      value: _vm.priceRequired,
      callback: function callback($$v) {
        _vm.priceRequired = $$v;
      },
      expression: "priceRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "Filer"
    },
    model: {
      value: _vm.additionalFilesRequired,
      callback: function callback($$v) {
        _vm.additionalFilesRequired = $$v;
      },
      expression: "additionalFilesRequired"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "inset": "",
      "hide-details": "",
      "label": "\n            Del av kursserie\n          "
    },
    model: {
      value: _vm.groupIdRequired,
      callback: function callback($$v) {
        _vm.groupIdRequired = $$v;
      },
      expression: "groupIdRequired"
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }