var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.panels, function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      staticClass: "pt-0"
    }, [_c('v-sheet', {
      attrs: {
        "rounded": "lg"
      }
    }, [_c(item, {
      tag: "component"
    })], 1)], 1)], 1);
  }), _c('v-card', {
    staticClass: "elevation-0",
    attrs: {
      "rounded": "lg"
    }
  }, [_c('v-card-title', [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.modal = true;
      }
    }
  }, [_vm._v("Opprett gjennomføringsplan")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push("/");
      }
    }
  }, [_vm._v("Avslutt uten Lagring")])], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "400"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        _vm.modal = false;
      }
    },
    model: {
      value: _vm.modal,
      callback: function callback($$v) {
        _vm.modal = $$v;
      },
      expression: "modal"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" Bekreft gjennomføringsplanen ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.modal = false;
      }
    }
  }, [_vm._v(" Avbryt ")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.approve
    }
  }, [_vm._v(" Godkjenn ")])], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }