
import { api } from "@/api/api";
import PlanEconomicsStrep from "@/components/implementation-plan/new/steps/PlanEconomicsStrep.vue";
import PlanInfoStep from "@/components/implementation-plan/new/steps/PlanInfoStep.vue";
import PlanStep from "@/components/implementation-plan/new/steps/PlanStep.vue";
import PlanTeachingMaterialStep from "@/components/implementation-plan/new/steps/PlanTeachingMaterialStep.vue";
import PlanTestsStep from "@/components/implementation-plan/new/steps/PlanTestsStep.vue";
import PlanWebStep from "@/components/implementation-plan/new/steps/PlanWebStep.vue";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { runStoreAction } from "@/shared/helpers/store.helpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "NewImplementationPlanPage",
  components: {
    PlanInfoStep,
    PlanStep,
    PlanEconomicsStrep,
    PlanWebStep,
    PlanTeachingMaterialStep,
    PlanTestsStep,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore<StoreState>();
    const approve = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!store.state.plans.defaultOptions.courseStatuses) {
          await runStoreAction(store, StoreModules.Plans, StoreActions.PlansActions.FetchDefaultOptions);
        }
        // courseStatuses will change to CurriculumStatuses someday on backend
        const statusId = store.state.plans.defaultOptions.courseStatuses?.find(
          (current) => current.description === "Godkjent"
        )?.id;
        if (!statusId) {
          throw new Error("Fant ikke statusId for godkjent");
        }

        await runStoreAction(store, StoreModules.Plans, StoreActions.PlansActions.FetchCurriculum, +route.params.id);
        await runStoreAction(
          store,
          StoreModules.Execution,
          StoreActions.ExecutionActions.PostExecutionPlan,
          route.params.id
        );
        await api.curriculum.approveCurriculumAsync(+route.params.id, { statusId });
        router.push(`/studieplaner`);
      });
    };

    return {
      approve,
    };
  },
  data() {
    return {
      modal: false,
      panels: [
        "PlanInfoStep",
        "PlanStep",
        "PlanEconomicsStrep",
        "PlanWebStep",
        "PlanTeachingMaterialStep",
        "PlanTestsStep",
      ],
    };
  },
  async mounted() {
    this.$store.commit("execution/RESET");
  },
});
//
