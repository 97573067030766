
// import { mapFields } from "vuex-map-fields";

import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "PlanWebStep",
  data: () => ({
    web: true,
  }),
  computed: {
    // ...mapFields("execution", ["executionPlan.economyRequired"]),
  },
});
